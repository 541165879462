:root {
    --animate-delay: 0.5s;
    --burkOrange1: #e05928;
    --burkOrange1Hover: #fb8c65;
    --transperantBlack1:#0000006b;
}

body {

    background: darkslategrey;
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color:white;
    height:100%;
    min-height: -webkit-fill-available;
}

.modal-content {

    background-color: #ffffff52;
    overflow-y: scroll;
    max-height: 95vh;

    background-color: var(--burkOrange1);
    border-radius: 15px;

    &::-webkit-scrollbar {
    display: none;
    }

}

.orangeModalWrap1 {

    & .modal-content {

        border:2px solid var(--burkOrange1);
        background-color: var(--transperantBlack1) !important;
        color:white;

        .cityCard {

            border-bottom: 2px solid rgba(220, 220, 220, 0.295);
            margin-bottom:20px;
            padding:6px;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }

    }

}



.centerWrap {

    display:flex;
    justify-content: center;
    padding:10px;
}

.btn-primary, .btn-primary:focus {

    font-weight: 800;
    background-color: var(--burkOrange1);
    border:none !important;
    box-shadow:none !important;
    text-transform: uppercase;

    &:hover {
        border:none;
        background-color: var(--burkOrange1Hover);
    }

    margin-left:3px;
    margin-right:3px;

}

.buttonCompact {

    padding: 5px;
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;

}


.globe {

    &__messageUI {

        z-index: 400;
        max-width: calc(100% - 1rem);
        background-color: rgba(0, 0, 0, 0.655);
        margin-left:auto;
        margin-right:auto;
        text-align: center;
        color:white;
        font-size: 1rem;
        padding:10px;
        border-radius: 15px;

    }

    &__threeSceneWrap {

        min-height: 100vh;
        height: 100vh;
        position: absolute;
        position: relative;
        overflow: hidden;
        width: 100%;
        min-width: 100%;

        background-image: url('https://dwg-spaces.sfo2.cdn.digitaloceanspaces.com/burk-globe/site-assets/globe-background-web.jpg');

    }


    &__locationLabel {

        border-radius:20px;
        background-color: var(--transperantBlack1);
        color: white;
        padding:12px;
        border:2px solid var(--burkOrange1);

        width:170px;


        & .closeIcon {

            position: absolute;
            right:5px;
            top:5px;



        }

    }

    &__alumniListHeader {

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 9px;

        & h4 {
            margin-bottom: 0px;
        }
    }

    &__alumniCard {

        text-transform: capitalize;
        color:black;
    }

    &__alumniPhotoWrap {


    }

    &__alumniPhoto {

        border-radius: 15px;
        width: auto;
        max-width: 100%;

    }

    &__introWrap {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2000;
        padding:5px;

        color:white;

        h1 {
            font-size: 3rem;
            text-transform: uppercase;
            font-weight: 700;
        }

        h2 {
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: 700;
        }


        & .burkISDLogo {
            margin-top:40px;
            width: 300px;
            margin-bottom:20px;
        }
    }



}

@media (max-width:700px) {


    .globe {

        &__introWrap {


            h1 {

                font-size: 1.4rem;

            }

            h2 {
                font-size: 2rem;
            }

        }

    }

}